const state = {
    snackbar: '',
}

//actions
const actions = {
    setSnack ({ commit }, snackbar) {
        commit('setSnack', snackbar)
    },
}

//mutations
const mutations = {
    setSnack (state, snackbar) {
        state.snackbar = snackbar
    }
}
  
export default {
    state,
    actions,
    mutations
}
  