<template>
  <v-snackbar v-model="showsnack" :color="color">
    <v-icon v-if="icon!=''" class="mr-1">{{icon}}</v-icon>
    {{message}}
    <template v-slot:action="{ attrs }">
        <v-btn
            icon
            v-bind="attrs" 
            @click.native="showsnack = false"
        >
            <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
    data () {
        return {
            showsnack: false,
            message: '',
            icon:'mdi-information-outline',
            color:'green'
        }
    },
    created () {
        this.$store.watch(state => state.snackbar.snackbar, () => {
            const msg = this.$store.state.snackbar.snackbar
            if (msg !== '') {
                this.showsnack = true
                this.message = this.$store.state.snackbar.snackbar.msg
                this.icon = this.$store.state.snackbar.snackbar.icon
                this.color = this.$store.state.snackbar.snackbar.color
                this.$store.dispatch('setSnack', '')
            }
        })
    }
}
</script>