import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../components/Dashboard/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    meta: {
      title:"Dashboard",
      userLevel : 3,
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title:"Dashboard",
      userLevel : 3
    }
  },
  {
    path: '/competitions',
    name: 'Competitions',
    component: () => import('../components/Competitions/index.vue'),
    meta: {
      title:"Competitions",
      userLevel : 3
    }
  },
  {
    path: '/competition/overview',
    name: 'CompetitionOverview',
    component: () => import('../components/Competitions/overview.vue'),
    meta: {
      title:"Competitions",
      userLevel : 3
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../components/Users/index.vue'),
    meta: {
      title:"Users",
      userLevel : 1
    }
  },
  {
    path: '/users/add',
    name: 'UsersCreate',
    component: () => import('../components/Users/create.vue'),
    meta: {
      title:"Create User",
      userLevel : 1
    }
  },
  {
    path: '/users/edit',
    name: 'UsersEdit',
    component: () => import('../components/Users/create.vue'),
    meta: {
      title:"Edit User",
      userLevel : 1
    }
  },
  {
    path: '/users/delete',
    name: 'UsersDelete',
    component: () => import('../components/Users/delete.vue'),
    meta: {
      title:"Delete User",
      userLevel : 1
    }
  },
  {
    path: '/sales-plan-measures',
    name: 'SalesPlanMeasures',
    component: () => import('../components/SalesPlanMeasures/index.vue'),
    meta: {
      title:"Sales Plan Measures",
      userLevel : 2
    }
  },
  {
    path: '/sales-plan-measures/add',
    name: 'SalesPlanMeasuresCreate',
    component: () => import('../components/SalesPlanMeasures/create.vue'),
    meta: {
      title:"Sales Plan Measures",
      userLevel : 2
    }
  },
  {
    path: '/sales-plan-measures/edit',
    name: 'SalesPlanMeasuresEdit',
    component: () => import('../components/SalesPlanMeasures/create.vue'),
    meta: {
      title:"Sales Plan Measures",
      userLevel : 2
    }
  },
  {
    path: '/sales-plan-measures/delete',
    name: 'SalesPlanMeasuresDelete',
    component: () => import('../components/SalesPlanMeasures/delete.vue'),
    meta: {
      title:"Sales Plan Measures",
      userLevel : 2
    }
  },
  {
    path: '/client-settings',
    name: 'ClientSettings',
    component: () => import('../components/ClientSettings/index.vue'),
    meta: {
      title:"Client Settings",
      userLevel : 2
    }
  },
  {
    path: '/client-types/add',
    name: 'ClientTypesCreate',
    component: () => import('../components/ClientSettings/create.vue'),
    meta: {
      title:"Client Settings",
      userLevel : 2
    }
  },
  {
    path: '/client-types/edit',
    name: 'ClientTypesEdit',
    component: () => import('../components/ClientSettings/create.vue'),
    meta: {
      title:"Client Settings",
      userLevel : 2
    }
  },
  {
    path: '/client-types/delete',
    name: 'ClientTypesDelete',
    component: () => import('../components/ClientSettings/delete.vue'),
    meta: {
      title:"Client Settings",
      userLevel : 2
    }
  },
  {
    path: '/client-custom-field/add',
    name: 'ClientCustomFieldCreate',
    component: () => import('../components/ClientSettings/createCustomField.vue'),
    meta: {
      title:"Client Settings",
      userLevel : 2
    }
  },
  {
    path: '/client-custom-field/edit',
    name: 'ClientCustomFieldEdit',
    component: () => import('../components/ClientSettings/createCustomField.vue'),
    meta: {
      title:"Client Settings",
      userLevel : 2
    }
  },
  {
    path: '/client-custom-field/delete',
    name: 'ClientCustomFieldDelete',
    component: () => import('../components/ClientSettings/deleteCustomField.vue'),
    meta: {
      title:"Client Settings",
      userLevel : 2
    }
  },
  {
    path: '/solutions-settings',
    name: 'DealsSettings',
    component: () => import('../components/DealsSettings/index.vue'),
    meta: {
      title:"Solutions Settings",
      userLevel : 2
    }
  },
  {
    path: '/solution-types/add',
    name: 'SolutionTypesCreate',
    component: () => import('../components/DealsSettings/create.vue'),
    meta: {
      title:"Solutions Settings",
      userLevel : 2
    }
  },
  {
    path: '/solution-types/edit',
    name: 'SolutionTypesEdit',
    component: () => import('../components/DealsSettings/create.vue'),
    meta: {
      title:"Solutions Settings",
      userLevel : 2
    }
  },
  {
    path: '/solution-types/delete',
    name: 'SolutionTypesDelete',
    component: () => import('../components/DealsSettings/delete.vue'),
    meta: {
      title:"Solutions Settings",
      userLevel : 2
    }
  },
  {
    path: '/deals-custom-field/add',
    name: 'DealCustomFieldCreate',
    component: () => import('../components/DealsSettings/createCustomField.vue'),
    meta: {
      title:"Solutions Settings",
      userLevel : 2
    }
  },
  {
    path: '/deals-custom-field/edit',
    name: 'DealCustomFieldEdit',
    component: () => import('../components/DealsSettings/createCustomField.vue'),
    meta: {
      title:"Solutions Settings",
      userLevel : 2
    }
  },
  {
    path: '/deals-custom-field/delete',
    name: 'DealCustomFieldDelete',
    component: () => import('../components/DealsSettings/deleteCustomField.vue'),
    meta: {
      title:"Solutions Settings",
      userLevel : 2
    }
  },
  {
    path: '/activity-types',
    name: 'ActivityTypes',
    component: () => import('../components/ActivityTypes/index.vue'),
    meta: {
      title:"Activity Types",
      userLevel : 2
    }
  },
  {
    path: '/activity-types/add',
    name: 'ActivityTypesCreate',
    component: () => import('../components/ActivityTypes/create.vue'),
    meta: {
      title:"Activity Types",
      userLevel : 2
    }
  },
  {
    path: '/activity-types/edit',
    name: 'ActivityTypesEdit',
    component: () => import('../components/ActivityTypes/create.vue'),
    meta: {
      title:"Activity Types",
      userLevel : 2
    }
  },
  {
    path: '/activity-types/delete',
    name: 'ActivityTypesDelete',
    component: () => import('../components/ActivityTypes/delete.vue'),
    meta: {
      title:"Activity Types",
      userLevel : 2
    }
  },
  {
    path: '/sales-plan',
    name: 'SalesPlan',
    component: () => import('../components/SalesPlan/index.vue'),
    meta: {
      title:"Sales Plan",
      userLevel : 3
    }
  },
  {
    path: '/deals',
    name: 'Deals',
    component: () => import('../components/Deals/index.vue'),
    meta: {
      title:"Deals",
      userLevel : 3
    }
  },
  {
    path: '/deals/add',
    name: 'DealsCreate',
    component: () => import('../components/Deals/create.vue'),
    meta: {
      title:"Deals",
      userLevel : 3
    }
  },
  {
    path: '/deals/edit',
    name: 'DealsEdit',
    component: () => import('../components/Deals/create.vue'),
    meta: {
      title:"Deals",
      userLevel : 3
    }
  },
  {
    path: '/deals/delete',
    name: 'DealsDelete',
    component: () => import('../components/Deals/delete.vue'),
    meta: {
      title:"Deals",
      userLevel : 3
    }
  },
  {
    path: '/relations',
    name: 'Relations',
    component: () => import('../components/Relations/index.vue'),
    meta: {
      title:"Relations",
      userLevel : 3
    }
  },
  {
    path: '/Relation/add',
    name: 'RelationCreate',
    component: () => import('../components/Relations/create.vue'),
    meta: {
      title:"Relations",
      userLevel : 3
    }
  },
  {
    path: '/Relation/Transitions',
    name: 'RelationTransitions',
    component: () => import('../components/Relations/transitions.vue'),
    meta: {
      title:"Relations",
      userLevel : 3
    }
  },
  {
    path: '/Relation/edit',
    name: 'RelationEdit',
    component: () => import('../components/Relations/create.vue'),
    meta: {
      title:"Relations",
      userLevel : 3
    }
  },
  {
    path: '/Relation/delete',
    name: 'RelationDelete',
    component: () => import('../components/Relations/delete.vue'),
    meta: {
      title:"Relations",
      userLevel : 3
    }
  },
  {
    path: '/activities',
    name: 'Activities',
    component: () => import('../components/Activities/index.vue'),
    meta: {
      title:"Activities",
      userLevel : 3
    }
  },
  {
    path: '/activities/add',
    name: 'ActivitiesCreate',
    component: () => import('../components/Activities/create.vue'),
    meta: {
      title:"Activities",
      userLevel : 3
    }
  },
  {
    path: '/activities/edit',
    name: 'ActivitiesEdit',
    component: () => import('../components/Activities/create.vue'),
    meta: {
      title:"Activities",
      userLevel : 3
    }
  },
  {
    path: '/activities/delete',
    name: 'ActivitiesDelete',
    component: () => import('../components/Activities/delete.vue'),
    meta: {
      title:"Activities",
      userLevel : 3
    }
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import('../components/Tools/index.vue'),
    meta: {
      title:"Tools",
      userLevel : 3
    }
  },
  {
    path: '/tools/leads_calculator',
    name: 'LeadsCalculator',
    component: () => import('../components/Tools/LeadsCalculator.vue'),
    meta: {
      title:"Tools",
      userLevel : 3
    }
  },
  {
    path: '/tools/cross_sell_calculator',
    name: 'CrossSellCalculator',
    component: () => import('../components/Tools/CrossSellCalculator.vue'),
    meta: {
      title:"Tools",
      userLevel : 3
    }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('../components/ResetPassword.vue'),
    meta: {
      title:"Reset Password",
      userLevel : 3
    }
  },
  {
    path: '/activate-account/:token',
    name: 'ActivateAccount',
    component: () => import('../components/ActivateAccount.vue'),
    meta: {
      title:"Activate Account",
      userLevel : 3
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _from, next) => {
  var userData = localStorage.getItem('user');
  if (userData != null) {
    let user = JSON.parse(userData)
    if(user.role <= to.meta.userLevel){
        next()
    }else{
      next({name:'Home'})
    }
  }else{
    next()
  }
});

const DEFAULT_TITLE = 'dripp';
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = "dripp - "+to.meta.title || DEFAULT_TITLE;
    });
});

export default router
