export default {
  setUser(user) {
    localStorage.user = JSON.stringify(user)
  },
  getUser(){
    return localStorage.user == null ? {} : JSON.parse(localStorage.user)
  },
  deleteUser(){
    delete localStorage.user
  },
}
