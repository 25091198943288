//initial state
const state = {
  authenticated: false,
  user: null,
}

//getters
const getters = {
  authenticated: state => state.authenticated,
  user: state => state.user,
}

//actions
const actions = {
  setAuthenticated ({ commit }, authenticated) {
    commit('setAuthenticated', authenticated)
  },
  setUser ({ commit }, user) {
    commit('setUser', user)
  },
}

//mutations
const mutations = {
  setAuthenticated(state, authenticated){
    state.authenticated = authenticated
  },
  setUser(state, user){
    state.user = user
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
