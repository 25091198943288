import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from "./store"
import vuexI18n from 'vuex-i18n';
import translationsEn from './locale/translationsEn.json'
import translationsNl from './locale/translationsNl.json'
import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.use(VueAxios,axios)
Vue.use(vuexI18n.plugin, store)
Vue.i18n.add('En', translationsEn);
Vue.i18n.add('Nl', translationsNl);
if(process.env.NODE_ENV === 'development'){
  Vue.i18n.set('En')
}else{
  Vue.i18n.set('Nl')
}

Vue.config.productionTip = false
Vue.prototype.$urlPrefix = process.env.NODE_ENV === 'development' ? 'https://dripp.streamliners.app' : ''

new Vue({
  vuetify,
  router,
  render: h => h(App),
  store
}).$mount('#app')
