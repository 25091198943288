<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
        <v-col xs="12" lg="5">

            <v-card
              elevation="2"
              light
            >
              <v-row>
                <v-col cols="12" lg="6">
                  <v-container fill-height>
                    <v-row justify="center" align="center">
                        <v-col cols="6">
                          <v-layout justify="center">
                            <img class="img-fluid" :src="require('@/assets/logo-full.svg')"/>
                          </v-layout>
                        </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col v-if="!forgotPass" cols="12" lg="6">
                  <v-form
                    justify="center"
                    v-model="valid"
                    ref="form"
                  >
                    <v-card-text>
                      <h3>{{ $t('Welcome back') }}</h3>

                      <v-text-field
                        :label="$t('Email address')"
                        required
                        class="mt-5"
                        v-model="loginForm.email"
                        :rules="emailRules"
                      ></v-text-field>

                      <v-text-field
                        :label="$t('Password')"
                        required
                        type="password"
                        v-model="loginForm.password"
                        :rules="passwordRules"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        block
                        color="primary"
                        @click="login"
                      >
                        {{ $t('Login') }}
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions>
                      <v-btn plain x-small class="text-caption" color="primary" @click.stop="forgotPass=true" >{{ $t('Forgot password') }}</v-btn>
                    </v-card-actions>
                    <v-card-actions class="d-block">
                      <v-alert
                        dense
                        type="error"
                        v-if="error.length > 0"
                      >
                        {{error}}
                      </v-alert>
                    </v-card-actions>
                  </v-form>
                </v-col>

                <v-col v-if="forgotPass" cols="12" lg="6">
                  <v-form
                    justify="center"
                    v-model="resetvalid"
                    ref="resetform"
                  >
                    <v-card-text>
                      <h3>{{ $t('Submit your email address to receive a password reset link') }}</h3>

                      <v-text-field
                        :label="$t('Email address')"
                        required
                        class="mt-5"
                        v-model="resetemail"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        block
                        color="primary"
                        @click="sendResetLink"
                      >
                        {{ $t('Send Reset Link') }}
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions>
                      <v-btn plain x-small class="text-caption" color="primary" @click.stop="forgotPass=false">{{ $t('Login') }}</v-btn>
                    </v-card-actions>
                    <v-card-actions class="d-block">
                      <v-alert
                        dense
                        type="error"
                        v-if="reseterror.length > 0"
                      >
                        {{reseterror}}
                      </v-alert>
                      <v-alert
                        dense
                        type="success"
                        v-if="success.length > 0"
                      >
                        {{success}}
                      </v-alert>
                    </v-card-actions>
                  </v-form>
                </v-col>
              </v-row>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import auth from '@/auth'
import axios from 'axios'

export default {
  data () {
    return {
      valid: false,
      resetvalid:false,
      forgotPass:false,
      resetemail:'',
      loginForm:{
        email: '',
        password: '',
      },
      emailRules: [
        v => !!v || this.$t('Email address is required'),
        v => /.+@.+/.test(v) || this.$t('Email address must be valid'),
      ],
      passwordRules: [
        v => (v && v.length >= 8) || this.$t('Password must be at least 8 characters'),
        v => /\W|_/g.test(v) || this.$t('Password must have at least one special character'),
        v => /[A-Z]/.test(v) || this.$t('Password must have at least one capital'),
      ],
      error: "",
      success: "",
      reseterror:'',
    }
  },
  methods: {
    sendResetLink(){
      var scope = this
      if(scope.$refs.resetform.validate()){
        scope.reseterror = ""
        scope.success = ""
        axios.post(this.$urlPrefix + "/Email/PasswordReset", {"email":this.resetemail})
        .then(() => {
          scope.success = scope.$t("Password reset link sent to email address")
        })
        .catch(function(e){
          scope.error = scope.$t(e.response.data)
        })
      }
    },
    login () {
      if(this.$refs.form.validate()){
        var scope = this
        scope.error = ""

        axios.post(this.$urlPrefix + "/Authentication/Login", this.loginForm)
        .then(response => {
          auth.setUser(response.data)
          scope.$store.dispatch('setAuthenticated', true)
          scope.$store.dispatch('setUser', auth.getUser())
          this.axios.defaults.headers.common['Authorization'] = auth.getUser().token
        })
        .catch(function(){
          scope.error = scope.$t("De inloggegevens zijn niet correct")
          scope.$store.dispatch('setAuthenticated', false)
          scope.$store.dispatch('setUser', null)
        })
      }
    },
  },
}
</script>

<style lang="scss">
button.text-caption {
    padding: 0 !important;
}
</style>
