<template>

  <div>
        <div>
            <template>
                <v-toolbar
                    flat
                >
                <v-toolbar-title>{{ $t('Dashboard') }}</v-toolbar-title>
                <v-spacer></v-spacer>

                </v-toolbar>
            </template>
        </div>
        <div class="mt-4">

            <div>
                <template>
                    <v-toolbar flat >
                        <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="startDate"
                                    class="mt-3 pr-1 top-filters shrink"
                                    v-bind="attrs"
                                    v-on="on"
                                    :label="$t('Start Date')"
                                    placeholder="dd-mm-yyyy"
                                    dense
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="startDateFormatted"
                                no-title
                                @input="menu2 = false"
                                :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="endDate"
                                    class="mt-3 pr-1 top-filters shrink"
                                    v-bind="attrs"
                                    v-on="on"
                                    :label="$t('End Date')"
                                    placeholder="dd-mm-yyyy"
                                    dense
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="endDateFormatted"
                                no-title
                                @input="menu3 = false"
                                :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <v-select
                            :items="dashboards"
                            item-text="name"
                            item-value="id"
                            v-model="options.dashboard"
                            :label="$t('Dashboard')"
                            class="mt-3 pr-1 top-filters shrink"
                            dense
                        >
                            <template v-slot:item="{item}">
                                <div>{{item.name}}</div>
                                <v-spacer></v-spacer>
                                <div >
                                    <v-btn @click.stop="openAddDashboardDialog(true,item)" x-small plain text icon class="ml-2 mr-1">
                                        <v-hover v-slot="{ hover }">
                                            <v-icon :color="hover? 'green': 'grey'"
                                            >
                                            mdi-pencil
                                            </v-icon>
                                        </v-hover>
                                    </v-btn>

                                    <v-btn @click.stop="openDeleteDashboardDialog(item.id,item.name)" x-small plain text icon class="">
                                        <v-hover v-slot="{ hover }">
                                            <v-icon  :color="hover? 'primary': 'grey'"
                                            >
                                            mdi-delete
                                            </v-icon>
                                        </v-hover>
                                    </v-btn>
                                </div>
                            </template>
                        </v-select>
                        <v-spacer></v-spacer>
                        <v-btn
                            fab
                            dark
                            color="primary"
                            @click.stop="openAddDashboardDialog(false)"
                        >
                            <v-icon
                            dark
                            >
                            mdi-plus
                            </v-icon>
                        </v-btn>

                    </v-toolbar>
                </template>
            </div>
            <div class="mt-4">
                <v-row v-if="kpiIdList.length ==  dashboardKpis.length &&
                 !addKpiDialog &&
                 !deleteKpiDialog &&
                 !addDashboardDialog &&
                 !deleteDashboardDialog
                 ">
                    <v-col
                    v-for="(kpi,index) in dashboardKpis" v-bind:key="index"
                    :cols="'12'"
                    :md="kpi.settings.width===0?'12':(kpi.settings.width==1?'6':'4')"
                     sm="6"
                    >
                            <v-card min-height="200" class="kpi-card">
                                <v-card-title>
                                    <div>{{kpi.title?kpi.title:'No title'}}</div>
                                    <v-spacer></v-spacer>
                                    <div class="card-action">
                                        <v-btn @click="openAddKpiDialog(true,kpi)" x-small plain text icon class="mr-2">
                                            <v-hover v-slot="{ hover }">
                                                <v-icon :color="hover? 'green': 'grey'"
                                                >
                                                mdi-pencil
                                                </v-icon>
                                            </v-hover>
                                        </v-btn>

                                        <v-btn @click="openDeleteKpiDialog(kpi.id,kpi.title)" x-small plain text icon class="">
                                            <v-hover v-slot="{ hover }">
                                                <v-icon  :color="hover? 'primary': 'grey'"
                                                >
                                                mdi-delete
                                                </v-icon>
                                            </v-hover>
                                        </v-btn>
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <div @click="shiftLeft(kpi)" class="chart-shif-left card-action">
                                        <v-hover v-slot="{ hover }">
                                            <v-btn :color="hover? 'primary': 'grey'" class="mp-btn mr-1" small outlined fab >
                                                <v-icon>mdi-chevron-left</v-icon>
                                            </v-btn>
                                        </v-hover>
                                    </div>
                                    <div v-if="kpi.settings.type!=2" class="measure-chart">
                                        <div class="bar-chart">
                                            <label v-if="kpi.settings.type==1 && kpi.settings.showTotalValue && kpi.settings.showExpectedValue && kpi.settings.showChartForEachUser">{{$t('Actual')}}</label>
                                            <apexchart :options="ChartOptions(kpi)" :series="ChartSeries(kpi)"></apexchart>
                                            <label v-if="kpi.settings.type==1 && kpi.settings.showTotalValue && kpi.settings.showExpectedValue && kpi.settings.showChartForEachUser">{{$t('Expected')}}</label>
                                            <apexchart v-if="kpi.settings.type==1 && kpi.settings.showTotalValue && kpi.settings.showExpectedValue && kpi.settings.showChartForEachUser" :options="ChartOptions(kpi,'showExpected')" :series="ChartSeries(kpi,'showExpected')"></apexchart>
                                            <apexchart v-if="kpi.settings.type==3 && kpi.settings.showExpectedValue && kpi.settings.showChartForEachUser" :options="ChartOptions(kpi,'showExpected')" :series="ChartSeries(kpi,'showExpected')"></apexchart>
                                            <apexchart v-if="kpi.settings.type==3 && kpi.settings.showTotalValue && kpi.settings.showChartForEachUser" :options="ChartOptions(kpi,'showTotal')" :series="ChartSeries(kpi,'showTotal')"></apexchart>
                                        </div>
                                    </div>

                                    <div v-if="kpi.settings.type==2" class="measure-chart">
                                        <div class="table-kpi">
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">

                                                            </th>
                                                            <th class="text-left">
                                                                {{$t('Current')}}
                                                            </th>
                                                            <th v-if="kpi.settings.showExpectedValue" class="text-left">
                                                                {{$t('Expected')}}
                                                            </th>
                                                            <th v-if="kpi.settings.showTotalValue" class="text-left">
                                                                {{$t('Planned')}}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="!kpi.settings.showChartForEachUser">
                                                        <tr>
                                                            <td v-if="getDashboardById(options.dashboard).isTeamDashboard == true">{{$t('Team')}}</td>
                                                            <td v-else>{{$store.state.user.user.fullName}}</td>
                                                            <td>{{kpi.values.T.currentvalue}}<span v-if="kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation">%</span></td>
                                                            <td v-if="kpi.settings.showExpectedValue">{{kpi.values.T.expectedValue}}<span v-if="kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation">%</span></td>
                                                            <td v-if="kpi.settings.showTotalValue">{{kpi.values.T.targetValue}}<span v-if="kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation">%</span></td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-if="kpi.settings.showChartForEachUser">
                                                        <tr
                                                        v-for="userValue in kpi.userValues" v-bind:key="userValue.userId"
                                                        >
                                                            <td>{{getUser(userValue.userId)}}</td>
                                                            <td>{{userValue.values.T.currentvalue}}<span v-if="kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation">%</span></td>
                                                            <td v-if="kpi.settings.showExpectedValue">{{userValue.values.T.expectedValue}}<span v-if="kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation">%</span></td>
                                                            <td v-if="kpi.settings.showTotalValue">{{userValue.values.T.targetValue}}<span v-if="kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation">%</span></td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </div>
                                    </div>

                                    <div @click="shiftRight(kpi)" class="chart-shif-right card-action">
                                        <v-hover v-slot="{ hover }">
                                            <v-btn class="mp-btn ml-1" :color="hover? 'primary': 'grey'" small outlined fab >
                                                <v-icon>mdi-chevron-right</v-icon>
                                            </v-btn>
                                        </v-hover>
                                    </div>
                                </v-card-text>
                            </v-card>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">
                        <v-card min-height="200" class="kpi-card add-kpi-card">
                            <v-card-title>
                                <div>{{$t('Add a new KPI')}}</div>
                            </v-card-title>
                            <v-card-text>
                                <div class="add-api">
                                    <v-btn
                                    fab
                                    dark
                                    color="primary"
                                    @click.stop="openAddKpiDialog(false)"
                                    >
                                        <v-icon
                                        dark
                                        >
                                        mdi-plus
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-dialog
        v-model="addDashboardDialog"
        max-width="600px"
        >
        <v-card>
            <v-card-title>
            <span class="text-h5">{{ addDashboardTitle }}</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form
                    ref="dashboardForm"
                    v-model="validDashboard"
                    lazy-validation
                >
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                            v-model="addDashboard.name"
                            :label="$t('Title*')"
                            :rules="dashboardRules.name"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1"
                            cols="12"
                            sm="6"
                        >
                            <v-select
                            v-model="addDashboard.userId"
                            :items="users"
                            item-text="fullName"
                            item-value="id"
                            :label="$t('User')"
                            ></v-select>
                        </v-col>
                        <v-col
                            v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1"
                            cols="12"
                            sm="6"
                        >
                            <v-checkbox
                            v-model="addDashboard.isTeamDashboard"
                            :label="$t('is Team Dashboard')"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <small>{{$t('*indicates required field') }}</small>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green"
                text
                @click="addDashboardDialog = false"
            >
                {{$t('Cancel')}}
            </v-btn>
            <v-btn
                color="primary"
                @click="saveDashboard"
                :disabled="!validDashboard"
            >
               {{$t('Save')}}
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <v-dialog
        v-model="deleteDashboardDialog"
        max-width="400px"
        >
        <v-card>
            <v-card-title>
            <span class="text-h5">{{ $t('Remove Dashboard') }}</span>
            </v-card-title>
            <v-card-text>{{$t('Are you sure you want to remove the Dashboard with title ')+deleteDashboardName+'?'}}</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="deleteDashboardDialog = false"
            >
                {{$t('Cancel')}}
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="DeleteDashboard"
            >
               {{$t('Yes')}}
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <v-dialog
        v-model="addKpiDialog"
        max-width="600px"
        >
        <v-card>
            <v-card-title>
            <span class="text-h5">{{ addKpiTitle }}</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >                   
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <v-select
                            v-model="addKpi.settings.salesPlanMeasureId"
                            :items="salesPlanMeasures"
                            item-text="name"
                            item-value="id"
                            :label="$t('Sales plan measure*')"
                            :rules="rules.salesPlan"
                            v-on:change="setTitle"
                            ></v-select>                            
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <v-select
                            v-model="addKpi.settings.type"
                            :items="dashboardKpiType"
                            item-text="name"
                            item-value="value"
                            :label="$t('Type*')"
                            :rules="rules.type"
                            ></v-select>
                        </v-col>
                    </v-row>                   
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                            v-model="addKpi.title"
                            :label="$t('Title*')"
                            :rules="rules.title"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="6"
                        >
                            <v-select
                            v-model="addKpi.settings.width"
                            :items="dashboardKpiWidth"
                            item-text="name"
                            item-value="value"
                            :label="$t('Width*')"
                            :rules="rules.width"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <v-checkbox
                            v-model="addKpi.settings.showTotalValue"
                            :label="$t('Show total value')"
                            ></v-checkbox>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <v-checkbox
                            v-model="addKpi.settings.showExpectedValue"
                            :label="$t('Show expected value')"
                            ></v-checkbox>
                        </v-col>
                        <v-col
                            v-if="addKpi.settings.type != 1"
                            cols="12"
                            sm="6"
                        >
                            <v-checkbox
                            v-model="addKpi.settings.showAsPercentage"
                            :label="$t('Show as percentage')"
                            ></v-checkbox>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <v-checkbox
                            v-model="addKpi.settings.showChartForEachUser"
                            :label="$t('Show chart for each user')"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <small>{{$t('*indicates required field') }}</small>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green"
                text
                @click="addKpiDialog = false"
            >
                {{$t('Cancel')}}
            </v-btn>
            <v-btn
                color="primary"
                @click="saveKpi"
                :disabled="!valid"
            >
               {{$t('Save')}}
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <v-dialog
        v-model="deleteKpiDialog"
        max-width="400px"
        >
        <v-card>
            <v-card-title>
            <span class="text-h5">{{ $t('Remove KPI') }}</span>
            </v-card-title>
            <v-card-text>{{$t('Are you sure you want to remove the KPI with title ')+deleteKpiName+'?'}}</v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="deleteKpiDialog = false"
            >
                {{$t('Cancel')}}
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="DeleteKpi"
            >
               {{$t('Yes')}}
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'

    export default {
        components:{
            'apexchart': VueApexCharts,
        },
        data(){
            return{
                valid:true,
                validDashboard:true,
                rules: {
                    title:[
                        value => !!value || this.$t('Required.'),
                        value => (value || '').length <= 100 || this.$t('Max 100 characters'),
                    ],
                    type:[
                        value => !!value || value == 0 || this.$t('Type is Required'),
                    ],
                    salesPlan:[
                        value => !!value || value == 0 || this.$t('Sale Plan is Required.'),
                    ],
                    width:[
                        value => !!value || value == 0 || this.$t('Width is Required.'),
                    ],
                },
                dashboardRules: {
                    name:[
                        value => !!value || this.$t('Required.'),
                        value => (value || '').length <= 100 || this.$t('Max 100 characters'),
                    ],
                },
                addDashboard:{},
                addDashboardDialog: false,
                addDashboardTitle:'',
                deleteDashboardDialog:false,
                deleteDashboardID:"",
                deleteDashboardName:"",

                addKpiDialog: false,
                addKpiTitle:'',
                deleteKpiDialog:false,
                deleteKpiID:"",
                deleteKpiName:"",
                users:[],
                dashboards:[],
                menu2:false,
                menu3:false,
                startDate:'01-01-'+new Date().getFullYear(),
                endDate:new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()+'-'+((new Date().getMonth()+1).toString().length==1?"0"+(new Date().getMonth()+1):(new Date().getMonth()+1))+'-'+new Date().getFullYear(),
                startDateFormatted:new Date().getFullYear()+'-01-01',
                endDateFormatted:new Date().getFullYear()+'-'+((new Date().getMonth()+1).toString().length==1?"0"+(new Date().getMonth()+1):(new Date().getMonth()+1))+'-'+new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate(),
                options: {
                    startDate:new Date().getFullYear()+'-01-01',
                    endDate:new Date().getFullYear()+'-'+((new Date().getMonth()+1).toString().length==1?"0"+(new Date().getMonth()+1):(new Date().getMonth()+1))+'-'+new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate(),
                    dashboard:'',
                },
                loading: true,
                kpiIdList:[],
                dashboardKpis:[],
                dashboardKpiType:[],
                dashboardKpiWidth:[],
                salesPlanMeasures:[],
                addKpi:{
                    settings:{
                      showExpectedValue: false,
                      showTotalValue: false,
                      showChartForEachUser: false,
                      showAsPercentage: false,
                    }
                },
                newKpi:{
                    settings:{

                    }
                },

            }
        },

        watch: {
            options: {
                handler () {
                  this.fetchKpiIdList()
                },
                deep: true,
            },
            startDateFormatted : {
                handler () {
                    this.startDate = this.formatDate(this.startDateFormatted)
                    this.options.startDate = this.startDateFormatted
                }
            },
            endDateFormatted : {
                handler () {
                    this.endDate = this.formatDate(this.endDateFormatted)
                    this.options.endDate = this.endDateFormatted
                }
            },
            startDate : {
                handler () {
                    this.startDateFormatted = this.reFormatDate(this.startDate)
                }
            },
            endDate : {
                handler () {
                    this.endDateFormatted = this.reFormatDate(this.endDate)
                }
            },

        },
        mounted ()
        {
            this.fetchKpiIdList();
        },
        created: function()
        {
            this.fetchDashboards()
            this.fetchUsers();
            this.fetchDashboardKpiType()
            this.fetchDashboardKpiWidth()
            this.fetchSalesPlanMeasure()
        },

        methods: {

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            },
            reFormatDate (date) {
                if (!date) return null

                const [day, month, year] = date.split('-')
                return `${year}-${month}-${day}`
            },
            getUser(userid){
              let filterUser = this.users.filter(function(user){
                return user.id == userid
              })
              return filterUser[0]!=undefined?filterUser[0].fullName:"";
            },
            fetchUsers()
            {
                let uri = this.$urlPrefix + '/User/CountAll?includeDeleted=true';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/User/GetList?start=0&count='+response.data+'&includeDeleted=true';
                    this.axios.get(uri2).then((response) => {
                        this.users = response.data;
                    });
                });
            },
            fetchDashboards(){

                let uri = this.$urlPrefix + '/Dashboard/GetList';

                this.axios.get(uri).then((response) => {
                    this.dashboards = response.data;
                    this.options.dashboard = this.dashboards[0].id
                    // this.fetchKpiIdList()
                });
            },
            fetchKpiIdList()
            {
                if(this.options.dashboard != undefined && this.options.dashboard != ""){
                    let uri2 = this.$urlPrefix + '/DashboardKpi/GetKpiIdListByDashboardId?dashboardId='+this.options.dashboard;

                    this.axios.get(uri2).then((response) => {
                        this.kpiIdList = response.data;
                        this.loading = false;
                        this.fetchDashboardKpi();
                    });
                }
            },
            fetchDashboardKpi()
            {
                this.dashboardKpis = [];
                for (const id in this.kpiIdList) {
                    let kpiId =  this.kpiIdList[id];

                    let uri2 = this.$urlPrefix + '/DashboardKpi/GetKpi?id='+kpiId;

                    if(this.options.startDate){
                        uri2 += '&startDate='+this.options.startDate;
                    }

                    if(this.options.endDate){
                        uri2 += '&endDate='+this.options.endDate;
                    }

                    this.axios.get(uri2).then((response) => {
                        this.dashboardKpis.push(response.data);
                        this.dashboardKpis.sort((a, b) => (a.sort > b.sort) ? 1 : -1);
                        this.loading = false;
                    });
                }
            },
            fetchDashboardKpiType()
            {
                let uri2 = this.$urlPrefix + '/DashboardKpiType/GetAll';

                this.axios.get(uri2).then((response) => {
                    this.dashboardKpiType = response.data;
                    this.loading = false;
                });

            },
            fetchDashboardKpiWidth()
            {
                let uri2 = this.$urlPrefix + '/DashboardKpiWidth/GetAll';

                this.axios.get(uri2).then((response) => {
                    this.dashboardKpiWidth = response.data;
                    this.loading = false;
                });

            },
            fetchSalesPlanMeasure(){
                let uri = this.$urlPrefix + '/SalesPlanMeasure/CountAll';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/SalesPlanMeasure/GetList?start=0&count='+response.data;
                    this.axios.get(uri2).then((response) => {
                        this.salesPlanMeasures = response.data;
                    });
                });
            },
            openAddDashboardDialog(isEdit,Dashboard){
                if(isEdit){
                    this.addDashboardTitle = this.$t('Edit Dashboard')
                    this.addDashboard = Dashboard
                }else{
                    this.addDashboardTitle = this.$t('Add Dashboard')

                    if(this.$refs.dashboardForm!=undefined){
                        this.$refs.dashboardForm.reset()
                    }
                    this.addDashboard = {};
                }
                this.addDashboardDialog = true
            },
            getDashboardById(dashboardId){
              return this.dashboards.find(d => d.id == dashboardId)
            },
            saveDashboard(){
                if(this.$refs.dashboardForm.validate()){
                    if (this.addDashboard.id != undefined && this.addDashboard.id != "") {
                        let uri = this.$urlPrefix + '/Dashboard/Update';
                        this.axios.put(uri, this.addDashboard).then((response) => {
                            if(response.status == 204 || response.status == 200){
                                    this.addDashboard = []
                                    this.fetchDashboards()
                                    this.addDashboardDialog = false
                            }
                        });
                    } else {
                        if(this.$store.state.user.user.role == 2){
                            this.addDashboard.userId = this.$store.state.user.user.id
                        }

                        let uri = this.$urlPrefix + '/Dashboard/Add';
                        this.axios.post(uri, this.addDashboard).then((response) => {
                            if(response.status == 200){
                                this.addDashboard = []
                                this.fetchDashboards()
                                this.addDashboardDialog = false
                            }
                        });
                    }
                }
            },
            openDeleteDashboardDialog(id,name){

                this.deleteDashboardID = id
                this.deleteDashboardName = name
                this.deleteDashboardDialog = true
            },
            DeleteDashboard(){
                if(this.deleteDashboardID !=""){
                    let uri = this.$urlPrefix + '/Dashboard/Delete?id='+this.deleteDashboardID;
                    this.axios.delete(uri).then((response) => {
                        if(response.data == true){
                            this.fetchDashboards()
                            this.deleteDashboardDialog = false
                            this.deleteDashboardID = ""
                        }else{
                            this.$store.dispatch('setSnack', {icon:"mdi-alert", color:'red', msg:this.$t("Unable to delete dahsboard")})
                        }
                    });
                }
            },
            openAddKpiDialog(isEdit,kpi){
                if(isEdit){
                    this.addKpiTitle = this.$t('Edit KPI')
                    this.addKpi = kpi
                }else{
                    this.addKpiTitle = this.$t('Add KPI')
                    if(this.$refs.form != undefined){
                        this.$refs.form.reset()
                    }
                    this.addKpi = this.newKpi
                }
                this.addKpiDialog = true
            },
            saveKpi(){
                if(this.$refs.form.validate()){
                    if(this.addKpi.settings.showTotalValue==undefined && this.addKpi.settings.showTotalValue==undefined){
                        this.$store.dispatch('setSnack', {icon:"mdi-alert", color:'red', msg:"`Show total value` or `Show expected value` should be selected"})
                        return false;
                    }

                    if (this.addKpi.id != undefined && this.addKpi.id != "") {
                        let uri = this.$urlPrefix + '/DashboardKpi/UpdateKpi';
                        this.axios.put(uri, this.addKpi).then((response) => {
                            if(response.status == 204 || response.status == 200){
                                    this.addKpi = this.newKpi
                                    this.fetchKpiIdList()
                                    this.loading = false
                                    this.addKpiDialog = false
                            }
                        });
                    } else {
                        this.addKpi.dashboardId = this.options.dashboard
                        let uri = this.$urlPrefix + '/DashboardKpi/AddKpi';
                        this.axios.post(uri, this.addKpi).then((response) => {
                            if(response.status == 200){
                                this.addKpi = this.newKpi
                                this.fetchKpiIdList()
                                this.addKpiDialog = false
                            }
                        });
                    }
                }
            },
            openDeleteKpiDialog(id,name){

                this.deleteKpiID = id
                this.deleteKpiName = name
                this.deleteKpiDialog = true
            },
            DeleteKpi(){
                if(this.deleteKpiID !=""){
                    let uri = this.$urlPrefix + '/DashboardKpi/DeleteKpi?id='+this.deleteKpiID;
                    this.axios.delete(uri).then((response) => {
                        if(response.data == true){
                            this.fetchKpiIdList()
                            this.deleteKpiDialog = false
                            this.deleteKpiID = ""
                        }else{
                            this.$store.dispatch('setSnack', {icon:"mdi-alert", color:'red', msg:"Unable to delete KPI"})
                        }
                    });
                }
            },
            shiftLeft(item){
              item.sort--;
              this.kpiShiftUpdate(item)
            },
            shiftRight(item){
              item.sort++;
              this.kpiShiftUpdate(item)
            },
            kpiShiftUpdate(item){
              let uri = this.$urlPrefix + '/DashboardKpi/UpdateKpiSort';
              this.loading = true;
              this.axios.put(uri, item).then(() => {
                  this.fetchKpiIdList();
              });
            },
            ChartOptions(kpi,option=""){
                let chartOptions = {};

                if(kpi.settings.type == 0){

                    if(kpi.settings.showTotalValue && kpi.settings.showExpectedValue){
                            chartOptions =  {
                                        chart: {
                                            height: 350,
                                            type: 'bar'
                                        },
                                        plotOptions: {
                                            bar: {
                                                horizontal: true,
                                            }
                                        },
                                        colors: ['#0050FF'],
                                        dataLabels: {
                                            formatter: function(val, opt) {
                                                const goals =
                                                opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                                                    .goals

                                                if (goals && goals.length) {
                                                    return `${val} / ${goals[0].value}`
                                                }
                                                return val
                                            }
                                        },
                                        legend: {
                                            show: true,
                                            showForSingleSeries: true,
                                            customLegendItems: [this.$t('Actual')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),this.$t('Expected')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),this.$t('Planned')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':'')],
                                            markers: {
                                                fillColors: ['#0050FF','#8bede6','#17dbcc']
                                            }
                                        },
                                    }
                    }else{
                        chartOptions =  {
                                        chart: {
                                            height: 200,
                                            type: 'bar'
                                        },
                                        plotOptions: {
                                            bar: {
                                                horizontal: true,
                                            }
                                        },
                                        colors: ['#0050FF'],
                                        dataLabels: {
                                            formatter: function(val, opt) {
                                                const goals =
                                                opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                                                    .goals

                                                if (goals && goals.length) {
                                                    return `${val} / ${goals[0].value}`
                                                }
                                                return val
                                            }
                                        },
                                        legend: {
                                            show: true,
                                            showForSingleSeries: true,
                                            customLegendItems: [this.$t('Actual')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':'')],
                                            markers: {
                                                fillColors: ['#0050FF']
                                            }
                                        }
                                    }
                    }

                }

                if(kpi.settings.type == 1){
                    let chartLabels = [];
                    kpi.userValues.forEach(userValue => {
                        chartLabels.push(this.getUser(userValue.userId)+' %')
                    })

                    if(kpi.settings.showTotalValue && kpi.settings.showExpectedValue){
                        if(kpi.settings.showChartForEachUser){
                            if(option=='showExpected'){
                                chartOptions = {
                                        chart: {
                                        height: 390,
                                        type: 'radialBar',
                                        },
                                        plotOptions: {
                                        radialBar: {
                                            offsetY: 0,
                                            startAngle: 0,
                                            endAngle: 270,
                                            hollow: {
                                            margin: 5,
                                            size: '30%',
                                            background: 'transparent',
                                            image: undefined,
                                            },
                                            dataLabels: {
                                            name: {
                                                show: false,
                                            },
                                            value: {
                                                show: false,
                                            }
                                            }
                                        }
                                        },
                                        labels:chartLabels,
                                        legend: {
                                        show: true,
                                        floating: true,
                                        fontSize: '16px',
                                        position: 'left',
                                        offsetX: 0,
                                        offsetY: 15,
                                        labels: {
                                            useSeriesColors: true,
                                        },
                                        markers: {
                                            size: 0
                                        },
                                        formatter: function(seriesName, opts) {
                                            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                                        },
                                        itemMargin: {
                                            vertical: 3
                                        }
                                        },
                                        responsive: [{
                                        breakpoint: 480,
                                        options: {
                                            legend: {
                                                show: false
                                            }
                                        }
                                        }]
                                    }
                            }else if(option=='showTotal'){
                                chartOptions = {
                                        chart: {
                                        height: 390,
                                        type: 'radialBar',
                                        },
                                        plotOptions: {
                                        radialBar: {
                                            offsetY: 0,
                                            startAngle: 0,
                                            endAngle: 270,
                                            hollow: {
                                            margin: 5,
                                            size: '30%',
                                            background: 'transparent',
                                            image: undefined,
                                            },
                                            dataLabels: {
                                            name: {
                                                show: false,
                                            },
                                            value: {
                                                show: false,
                                            }
                                            }
                                        }
                                        },
                                        labels:chartLabels,
                                        legend: {
                                        show: true,
                                        floating: true,
                                        fontSize: '16px',
                                        position: 'left',
                                        offsetX: 0,
                                        offsetY: 15,
                                        labels: {
                                            useSeriesColors: true,
                                        },
                                        markers: {
                                            size: 0
                                        },
                                        formatter: function(seriesName, opts) {
                                            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                                        },
                                        itemMargin: {
                                            vertical: 3
                                        }
                                        },
                                        responsive: [{
                                        breakpoint: 480,
                                        options: {
                                            legend: {
                                                show: false
                                            }
                                        }
                                        }]
                                    }
                            }else{
                                chartOptions = {
                                        chart: {
                                        height: 390,
                                        type: 'radialBar',
                                        },
                                        plotOptions: {
                                        radialBar: {
                                            offsetY: 0,
                                            startAngle: 0,
                                            endAngle: 270,
                                            hollow: {
                                            margin: 5,
                                            size: '30%',
                                            background: 'transparent',
                                            image: undefined,
                                            },
                                            dataLabels: {
                                            name: {
                                                show: false,
                                            },
                                            value: {
                                                show: false,
                                            }
                                            }
                                        }
                                        },
                                        labels:chartLabels,
                                        legend: {
                                        show: true,
                                        floating: true,
                                        fontSize: '16px',
                                        position: 'left',
                                        offsetX: 0,
                                        offsetY: 15,
                                        labels: {
                                            useSeriesColors: true,
                                        },
                                        markers: {
                                            size: 0
                                        },
                                        formatter: function(seriesName, opts) {
                                            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                                        },
                                        itemMargin: {
                                            vertical: 3
                                        }
                                        },
                                        responsive: [{
                                        breakpoint: 480,
                                        options: {
                                            legend: {
                                                show: false
                                            }
                                        }
                                        }]
                                    }
                            }
                        }else{
                            chartOptions = {
                                        chart: {
                                        height: 390,
                                        type: 'radialBar',
                                        },
                                        plotOptions: {
                                        radialBar: {
                                            offsetY: 0,
                                            startAngle: 0,
                                            endAngle: 270,
                                            hollow: {
                                            margin: 5,
                                            size: '30%',
                                            background: 'transparent',
                                            image: undefined,
                                            },
                                            dataLabels: {
                                            name: {
                                                show: false,
                                            },
                                            value: {
                                                show: false,
                                            }
                                            }
                                        }
                                        },
                                        labels: [this.$t('Actual')+' %', this.$t('Expected')+' %'],
                                        legend: {
                                        show: true,
                                        floating: true,
                                        fontSize: '16px',
                                        position: 'left',
                                        offsetX: 0,
                                        offsetY: 15,
                                        labels: {
                                            useSeriesColors: true,
                                        },
                                        markers: {
                                            size: 0
                                        },
                                        formatter: function(seriesName, opts) {
                                            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                                        },
                                        itemMargin: {
                                            vertical: 3
                                        }
                                        },
                                        responsive: [{
                                        breakpoint: 480,
                                        options: {
                                            legend: {
                                                show: false
                                            }
                                        }
                                        }]
                                    }
                        }
                    }else{
                        chartOptions = {
                                        chart: {
                                        type: 'radialBar',
                                        offsetY: -20,
                                        sparkline: {
                                            enabled: true
                                        }
                                        },
                                        plotOptions: {
                                        radialBar: {
                                            startAngle: -90,
                                            endAngle: 90,
                                            track: {
                                            background: "transparent",
                                            strokeWidth: '87%',
                                            margin: 5, // margin is in pixels
                                            dropShadow: {
                                                enabled: true,
                                                top: 2,
                                                left: 0,
                                                color: '#999',
                                                opacity: 1,
                                                blur: 2
                                            }
                                            },
                                            dataLabels: {
                                            name: {
                                                show: false
                                            },
                                            value: {
                                                offsetY: -1,
                                                fontSize: '20px'
                                            }
                                            }
                                        }
                                        },
                                        labels:chartLabels,
                                        legend: {
                                          show: kpi.settings.showChartForEachUser ? true : false,
                                          floating: true,
                                          fontSize: '16px',
                                          position: 'left',
                                          offsetX: 0,
                                          offsetY: 15,
                                          labels: {
                                              useSeriesColors: true,
                                          },
                                          markers: {
                                              size: 0
                                          },
                                          formatter: function(seriesName, opts) {
                                              return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                                          },
                                          itemMargin: {
                                              vertical: 3
                                          }
                                          },
                                        grid: {
                                        padding: {
                                            top: -10
                                        }
                                        },
                                    }
                    }
                }

                if(kpi.settings.type == 3){
                    if(kpi.settings.showTotalValue && kpi.settings.showExpectedValue){
                        if(kpi.settings.showChartForEachUser){
                            if(option=='showExpected'){
                                chartOptions = {
                                        chart: {
                                            height: 350,
                                            type: 'line',
                                            zoom: {
                                                enabled: false
                                            }
                                        },
                                        dataLabels: {
                                            enabled: false
                                        },
                                        stroke: {
                                            curve: 'straight'
                                        },
                                        title: {
                                            text: this.$t('Expected')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                            align: 'left'
                                        },
                                        grid: {
                                            row: {
                                                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                                opacity: 0.5
                                            },
                                        },
                                        xaxis: {
                                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
                                        }
                                    }
                            }else if(option=='showTotal'){
                                chartOptions = {
                                        chart: {
                                            height: 350,
                                            type: 'line',
                                            zoom: {
                                                enabled: false
                                            }
                                        },
                                        dataLabels: {
                                            enabled: false
                                        },
                                        stroke: {
                                            curve: 'straight'
                                        },
                                        title: {
                                            text: this.$t('Planned')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                            align: 'left'
                                        },
                                        grid: {
                                            row: {
                                                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                                opacity: 0.5
                                            },
                                        },
                                        xaxis: {
                                            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
                                        }
                                    }
                            }else{
                                chartOptions = {
                                        chart: {
                                        height: 350,
                                        type: 'line',
                                        zoom: {
                                            enabled: false
                                        }
                                        },
                                        dataLabels: {
                                        enabled: false
                                        },
                                        stroke: {
                                        curve: 'straight'
                                        },
                                        title: {
                                        text: this.$t('Actual')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                        align: 'left'
                                        },
                                        grid: {
                                        row: {
                                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                            opacity: 0.5
                                        },
                                        },
                                        xaxis: {
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
                                        }
                                    }
                            }
                        }else{
                            chartOptions = {
                                        chart: {
                                        height: 350,
                                        type: 'line',
                                        zoom: {
                                            enabled: false
                                        }
                                        },
                                        dataLabels: {
                                        enabled: false
                                        },
                                        stroke: {
                                        curve: 'straight'
                                        },
                                        grid: {
                                        row: {
                                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                            opacity: 0.5
                                        },
                                        },
                                        xaxis: {
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
                                        }
                                    }
                        }


                    }else{
                        if(kpi.settings.showChartForEachUser){
                            chartOptions = {
                                        chart: {
                                        height: 350,
                                        type: 'line',
                                        zoom: {
                                            enabled: false
                                        }
                                        },
                                        dataLabels: {
                                        enabled: false
                                        },
                                        stroke: {
                                        curve: 'straight'
                                        },
                                        title: {
                                          text: (option == 'showTotal' ? this.$t('Planned')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':'') :
                                            (option == 'showExpected' ? this.$t('Expected')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':'') : this.$t('Actual'))+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':'')),
                                          align: 'left'
                                        },
                                        grid: {
                                        row: {
                                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                            opacity: 0.5
                                        },
                                        },
                                        xaxis: {
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
                                        }
                                    }
                        }else{
                            chartOptions = {
                                        chart: {
                                        height: 350,
                                        type: 'line',
                                        zoom: {
                                            enabled: false
                                        }
                                        },
                                        dataLabels: {
                                        enabled: false
                                        },
                                        stroke: {
                                        curve: 'straight'
                                        },
                                        grid: {
                                        row: {
                                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                            opacity: 0.5
                                        },
                                        },
                                        xaxis: {
                                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
                                        }
                                    }
                        }
                    }


                }
                return chartOptions;
            },
            ChartSeries(kpi,option=""){
                let chartSeries = []
                if(kpi.settings.type == 0){

                    if(kpi.settings.showTotalValue && kpi.settings.showExpectedValue){

                        if(kpi.settings.showChartForEachUser){
                            chartSeries =   [{
                              name: this.$t('Actual')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                              data: []
                            }]
                            kpi.userValues.forEach(userValue => {
                               chartSeries[0].data.push({
                                                        x: this.getUser(userValue.userId),
                                                        y: userValue.values.T.currentvalue,
                                                        goals: [
                                                            {
                                                                name: this.$t('Expected')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                                value: userValue.values.T.expectedValue,
                                                                strokeWidth: 5,
                                                                strokeColor: '#8bede6'
                                                            },
                                                            {
                                                                name: this.$t('Planned')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                                value: userValue.values.T.targetValue,
                                                                strokeWidth: 5,
                                                                strokeColor: '#17dbcc'
                                                            },
                                                        ]
                                                    })
                            });
                        }else{
                            chartSeries =   [
                                                {
                                                    name: this.$t('Actual')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                    data: [
                                                        {
                                                            x: '',
                                                            y: kpi.values.T.currentvalue,
                                                            goals: [
                                                                {
                                                                    name: this.$t('Expected')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                                    value: kpi.values.T.expectedValue,
                                                                    strokeWidth: 5,
                                                                    strokeColor: '#8bede6'
                                                                },
                                                                {
                                                                    name: this.$t('Planned')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                                    value: kpi.values.T.targetValue,
                                                                    strokeWidth: 5,
                                                                    strokeColor: '#17dbcc'
                                                                },
                                                            ]
                                                        },

                                                    ]
                                                }
                                        ]
                        }
                    }
                    if(kpi.settings.showTotalValue && !kpi.settings.showExpectedValue){
                        if(kpi.settings.showChartForEachUser){
                            chartSeries = [{
                              name: this.$t('Actual')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                              data: []
                            }]

                            kpi.userValues.forEach(userValue => {
                               chartSeries[0].data.push({
                                                      x: this.getUser(userValue.userId),
                                                      y: userValue.values.T.currentvalue,
                                                      goals: [{
                                                          name: this.$t('Planned')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                          value: userValue.values.T.targetValue,
                                                          strokeWidth: 5,
                                                          strokeColor: '#17dbcc'
                                                      }],
                                                    })
                            });
                        }else{
                            chartSeries =   [
                                                {
                                                    name: this.$t('Actual')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                    data: [
                                                        {
                                                            x: '',
                                                            y: kpi.values.T.currentvalue,
                                                            goals: [{
                                                                name: this.$t('Planned')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                                value: kpi.values.T.targetValue,
                                                                strokeWidth: 5,
                                                                strokeColor: '#17dbcc'
                                                            }],
                                                        },

                                                    ]
                                                }
                                        ]
                        }
                    }

                    if(!kpi.settings.showTotalValue && kpi.settings.showExpectedValue){
                        if(kpi.settings.showChartForEachUser){
                          chartSeries = [{
                            name: this.$t('Actual')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                            data: []
                          }]

                          kpi.userValues.forEach(userValue => {
                             chartSeries[0].data.push({
                                              x: this.getUser(userValue.userId),
                                              y: userValue.values.T.currentvalue,
                                              goals: [{
                                                  name: this.$t('Planned')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                  value: userValue.values.T.expectedValue,
                                                  strokeWidth: 5,
                                                  strokeColor: '#8bede6'
                                              }],
                                            })
                          });
                        }else{
                            chartSeries =   [
                                                {
                                                    name: this.$t('Actual')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                    data: [
                                                        {
                                                            x: '',
                                                            y: kpi.values.T.currentvalue,
                                                            goals: [{
                                                                name: this.$t('Planned')+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                                value: kpi.values.T.expectedValue,
                                                                strokeWidth: 5,
                                                                strokeColor: '#8bede6'
                                                            }],
                                                        },

                                                    ]
                                                }
                                        ]
                        }

                    }

                }

                if(kpi.settings.type == 1){

                    if(kpi.settings.showTotalValue && kpi.settings.showExpectedValue){

                        if(kpi.settings.showChartForEachUser){

                            if(option=='showExpected'){
                                chartSeries =   []
                                kpi.userValues.forEach(userValue => {
                                    chartSeries.push(userValue.values.T.expectedValue)
                                })
                            }else if(option=='showTotal'){
                                chartSeries =   []
                                kpi.userValues.forEach(userValue => {
                                    chartSeries.push(userValue.values.T.targetValue)
                                })
                            }else{
                                chartSeries =   []
                                kpi.userValues.forEach(userValue => {
                                    chartSeries.push(userValue.values.T.currentvalue)
                                })
                            }
                        }else{
                            chartSeries = [kpi.values.T.currentvalue, kpi.values.T.expectedValue]
                        }
                    }else{
                        if(kpi.settings.showTotalValue && !kpi.settings.showExpectedValue){
                            if(kpi.settings.showChartForEachUser){
                                chartSeries =   []
                                kpi.userValues.forEach(userValue => {
                                    chartSeries.push(userValue.values.T.currentvalue)
                                })
                            }else{
                                chartSeries = [kpi.values.T.currentvalue]
                            }

                        }else{
                            if(kpi.settings.showChartForEachUser){
                                chartSeries =   []
                                kpi.userValues.forEach(userValue => {
                                    chartSeries.push((userValue.values.T.currentvalue/userValue.values.T.expectedValue*100).toFixed(2))
                                })
                            }else{
                                chartSeries = [(kpi.values.T.currentvalue/kpi.values.T.expectedValue*100).toFixed(2)]
                            }

                        }

                    }

                }

                if(kpi.settings.type == 3){

                    if(kpi.settings.showTotalValue && kpi.settings.showExpectedValue){

                        if(kpi.settings.showChartForEachUser){
                            if(option=='showExpected'){
                                chartSeries =   []
                                kpi.userValues.forEach(userValue => {

                                    let userV =   {
                                                name: this.getUser(userValue.userId)+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                data: []
                                            }
                                    for (let month in userValue.values){
                                        userV.data.push(userValue.values[month].expectedValue)
                                    }

                                    chartSeries.push(userV)
                                })
                            }else if(option=='showTotal'){
                                chartSeries =   []
                                kpi.userValues.forEach(userValue => {

                                    let userV =   {
                                                name: this.getUser(userValue.userId)+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                data: []
                                            }
                                    for (let month in userValue.values){
                                        userV.data.push(userValue.values[month].targetValue)
                                    }

                                    chartSeries.push(userV)
                                })
                            }else{
                                chartSeries =   []
                                kpi.userValues.forEach(userValue => {

                                    let userV =   {
                                                name: this.getUser(userValue.userId)+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                data: []
                                            }
                                    for (let month in userValue.values){
                                        userV.data.push(userValue.values[month].currentvalue)
                                    }

                                    chartSeries.push(userV)
                                })
                            }

                        }else{
                                chartSeries =   []
                                let currentV = {
                                                name: this.$t("Actual")+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                data: []
                                            }
                                let expectedV = {
                                                name: this.$t("Expected")+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                data: []
                                            }
                                let targetV = {
                                                name: this.$t("Planned")+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                data: []
                                            }
                                for (let month in kpi.values){
                                    currentV.data.push(kpi.values[month].currentvalue)
                                    expectedV.data.push(kpi.values[month].expectedValue)
                                    targetV.data.push(kpi.values[month].targetValue)
                                }

                                chartSeries.push(currentV)
                                chartSeries.push(expectedV)
                                chartSeries.push(targetV)
                        }
                    }else{
                        if(kpi.settings.showTotalValue && !kpi.settings.showExpectedValue){
                            if(kpi.settings.showChartForEachUser){
                                chartSeries =   []
                                kpi.userValues.forEach(userValue => {
                                    let userV =   {
                                                name: this.getUser(userValue.userId)+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                data: []
                                            }
                                    for (let month in userValue.values){
                                        userV.data.push(option == 'showTotal' ? userValue.values[month].targetValue : userValue.values[month].currentvalue)
                                    }

                                    chartSeries.push(userV)
                                })
                            }else{
                                chartSeries =   [
                                                  {
                                                    name: this.$t("Actual")+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                    data: []
                                                  },
                                                  {
                                                    name: this.$t("Planned")+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                    data: []
                                                  }
                                                ]
                                for (let month in kpi.values){
                                    chartSeries[0].data.push(kpi.values[month].currentvalue)
                                    chartSeries[1].data.push(kpi.values[month].targetValue)
                                }
                            }
                        }else{
                            if(kpi.settings.showChartForEachUser){
                                chartSeries =   []
                                kpi.userValues.forEach(userValue => {

                                    let userV =   {
                                                name: this.getUser(userValue.userId)+(kpi.settings.showAsPercentage?' %':''),
                                                data: []
                                            }
                                    for (let month in userValue.values){
                                        userV.data.push(option=='showExpected' ? userValue.values[month].expectedValue : userValue.values[month].currentvalue)
                                    }

                                    chartSeries.push(userV)
                                })
                            }else{
                                chartSeries =   [
                                                  {
                                                    name: this.$t("Actual")+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                    data: []
                                                  },
                                                  {
                                                    name: this.$t("Expected")+(kpi.settings.showAsPercentage || kpi.settings.isPercentageCalculation?' %':''),
                                                    data: []
                                                  }
                                                ]
                                for (let month in kpi.values){
                                    chartSeries[0].data.push(kpi.values[month].currentvalue)
                                    chartSeries[1].data.push(kpi.values[month].expectedValue)
                                }
                            }
                        }

                    }

                }
                return chartSeries;
            },
            setTitle(){
                if(this.addKpi.title == "" || this.addKpi.title == null){
                    this.addKpi.title = this.salesPlanMeasures.find(s => s.id == this.addKpi.settings.salesPlanMeasureId).name
                }
            }
        }
    }
</script>
<style lang="scss">
    .top-filters{
        width: 150px;
    }

    .chart-shif-left {
        position: absolute;
        z-index: 99;
        left: 5px;
        top: 50%;
    }

    .chart-shif-right {
        position: absolute;
        z-index: 99;
        right: 5px;
        top: 50%;
    }
    .card-action {
        display: none;
    }

    .kpi-card:hover .card-action {
        display: block;
    }
    .bar-chart label {
        font-size: 18px;
        color: #212121;
        font-weight: 500;
    }
    .add-api {
        width: 100%;
        height: 100%;
        text-align: center;
        top: 50%;
        bottom: 50%;
        position: absolute;
    }

    .kpi-card.v-card {
        height: 100%;
    }
    @media only screen and (max-width: 600px) {
        .kpi-card .card-action {
            display: block;
        }
    }
</style>
