import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#0050FF",
        dark: '#0c2556',
        green: '#17dbcc',
        navbc:'#f9f8f9',
      },
    }
  },
}

export default new Vuetify(opts)
