<template>
  <v-app class="grey">
    <Snackbar />
    <template v-if="loading">
      <v-container fill-height>
        <v-row justify="center" align="center">
            <v-col cols="12" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
            </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="authenticated && $route.name != 'ResetPassword' && $route.name != 'ActivateAccount'">
      <v-navigation-drawer
        v-model="menuVisible"
        app
        color="navbc"
        light
        :mini-variant="mini"
        mini-variant-width="90"
        stateless
        class="d-none d-sm-flex"

      >
        <v-list-item height="" class="mt-2">
            <v-list-item-title :class="mini ? 'text-center': ''" height="60">
              <img :src="require('@/assets/logo.svg')" :width="'40'" />
            </v-list-item-title>
            <v-btn
              icon
              @click.stop="menuVisible = !menuVisible"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        </v-list-item>

        <template v-if="user.role == 0  || user.role == 1">
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-if="user.role == 0"
                link
                :to="{ name: 'Users' }"
              >
                <v-list-item-icon :title="$t('Users')">
                  <v-icon>mdi-account-multiple-plus</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('Users') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="user.role == 0 || user.role == 1"
                link
                :to="{ name: 'ClientSettings' }"
              >
                <v-list-item-icon :title="$t('Client Settings')">
                  <v-icon>mdi-account-edit</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('Client Settings') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="user.role == 0 || user.role == 1"
                link
                :to="{ name: 'DealsSettings' }"
              >
                <v-list-item-icon :title="$t('Solutions Settings')">
                  <v-icon>mdi-package-variant-closed</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('Solutions Settings') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="user.role == 0 || user.role == 1"
                link
                :to="{ name: 'ActivityTypes' }"
              >
                <v-list-item-icon :title="$t('Types of activities')">
                  <v-icon>mdi-playlist-edit</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('Types of activities') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="user.role == 0 || user.role == 1"
                link
                :to="{ name: 'SalesPlanMeasures' }"
              >
                <v-list-item-icon :title="$t('Sales Plan Measures')">
                  <v-icon>mdi-calculator-variant</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('Sales Plan Measures') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </template>

        <v-divider v-if="user.role == 0 || user.role == 1"></v-divider>

        <v-list>
          <v-list-item-group>
            <v-list-item
              link
              :to="{ name: 'Dashboard' }"
            >
              <v-list-item-icon :title="$t('Dashboard')">
                <v-icon>mdi-view-dashboard-variant</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t('Dashboard') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              link
              :to="{ name: 'Competitions' }"
            >
              <v-list-item-icon :title="$t('Competitions')">
                <v-icon>mdi-medal-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t('Competitions') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              link
              :to="{ name: 'SalesPlan' }"
            >
              <v-list-item-icon :title="$t('Sales plan')">
                <v-icon>mdi-table</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t('Sales plan') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              link
              :to="{ name: 'Deals' }"

            >
              <v-list-item-icon :title="$t('Deals')">
                <v-icon>mdi-clipboard-account-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t('Deals') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              link
              :to="{ name: 'Relations' }"
            >
              <v-list-item-icon :title="$t('Relations')">
                <v-icon>mdi-account-group-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t('Relations') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              link
              :to="{ name: 'Activities' }"
            >
              <v-list-item-icon :title="$t('Activities')">
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t('Activities') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              link
              :to="{ name: 'Tools' }"
            >
              <v-list-item-icon :title="$t('Tools')">
                <v-icon>mdi-calculator-variant-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t('Tools') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <template v-slot:append>
          <v-footer id="footer" color="navbc" class="justify-bottom">
            <span class="caption " color="navbc">&copy;dripp</span>
          </v-footer>
        </template>
      </v-navigation-drawer>

      <v-app-bar
        app
        light
        elevation="0"
        color="navbc"
      >
        <v-app-bar-nav-icon @click.stop="mini = !mini,menuVisible = true" class="ml-1 d-none d-sm-flex"/>
        <img :src="require('@/assets/logo.svg')" width="40" class="d-flex d-sm-none ml-2" />
        <v-spacer></v-spacer>
        <v-hover v-slot="{ hover }">
          <v-avatar color="primary" class="mr-3">
            <v-img :src="profilePicture" class="" :alt="avText">
              <template v-slot:placeholder>
                <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
                >
                {{avText}}
                </v-row>
              </template>
            </v-img>
            <v-btn v-if="hover"  @click="openUpdateProfileDialog()" x-small plain text icon class="profile-edit-btn">
                <v-icon color="white"
                >
                mdi-pencil
                </v-icon>
            </v-btn>
          </v-avatar>
        </v-hover>
        {{userFullname}}

        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="ml-3"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list light>
            <v-list-item-group>
              <v-list-item @click="logout()">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main class="blue-white lighten-5">
        <v-container fluid class="blue-white--text text--darken-4">
          <transition
            name="fade"
            mode="out-in"
          >
            <router-view />
          </transition>
        </v-container>
      </v-main>

      <v-bottom-navigation
        app
        fixed
        grow
        color="primary"
        class="d-flex d-sm-none"
      >
        <v-btn :to="{ name: 'Dashboard' }">
          <span>Dashboard</span>
          <v-icon>mdi-view-dashboard-variant</v-icon>
        </v-btn>

        <v-btn :to="{ name: 'Competitions' }">
          <span>Competitions</span>
          <v-icon>mdi-medal-outline</v-icon>
        </v-btn>

        <v-btn :to="{ name: 'Relations' }">
          <span>Relations</span>
          <v-icon>mdi-account-group-outline</v-icon>
        </v-btn>

        <v-btn :to="{ name: 'Activities' }">
          <span>Activities</span>
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>

        <v-btn
            :to="{ name: 'ActivitiesCreate' }"
            fab
            dark
            color="primary"
        >
            <v-icon
            dark
            color="white"
            >
            mdi-plus
            </v-icon>
        </v-btn>

      </v-bottom-navigation>

      <v-dialog
        v-model="updateProfileDialog"
        max-width="600px"
      >
        <v-card>
            <v-card-title>
            <span class="text-h5">{{$t("Update Profile Picture")}}</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form
                    ref="updateProfileDialogForm"
                    lazy-validation
                >
                    <v-row>
                        <v-col
                            cols="12"
                            class="profile-popup"
                        >
                          <div class="user-profile">
                            <v-avatar v-if="!cropDialog" color="primary" class="" >
                              <v-img :src="profilePicture" class="profile-img" :alt="avText">
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    {{avText}}
                                    </v-row>
                                  </template>
                              </v-img>
                            </v-avatar>
                            <cropper v-if="cropDialog"
                            ref="cropper"
                            :src="selectedFile"
                            :stencil-props="{
                              aspectRatio: 1/1,
                            }"
                            />
                          </div>
                          <div class="text-center change-image-btns">
                            <v-btn
                              v-if="!cropDialog"
                              color="primary"
                              class="ma-2 white--text"
                              @click="$refs.FileInput.click()"
                            >
                              {{$t('Choose a new profile picture')}}
                            </v-btn>
                            <v-btn v-if="cropDialog" :loading="profileUploading" class="primary mt-2" @click="updateProfilePicture()">{{$t('Crop & Upload Image')}}
                              <v-icon
                                right
                              >
                                mdi-cloud-upload
                              </v-icon>
                            </v-btn>
                          </div>
                          <input ref="FileInput" type="file" style="display: none;" @change="onFileSelect" @click="onClickSelectFile" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green"
                text
                @click="updateProfileDialog = false,cropDialog=false"
            >
                {{$t('Close')}}
            </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="competitionMessage"
        v-model="showCompetitionMessageDialog"
        max-width="600px"
      >
        <v-card>
            <v-card-title class="text-center">
              <v-container>
                <span class="text-h5 p-5">{{$t(competitionMessage.message)}}</span>
                <v-btn
                  icon
                  absolute
                  top
                  right
                  style="right:5px;top:5px;"
                  @click="showCompetitionMessageDialog = !showCompetitionMessageDialog"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-container>
            </v-card-title>
            <v-card-text class="text-center">
              <v-container class="mt-4 mb-4" v-html="competitionMessage.gif.code">
              </v-container>

              <h2 class="mb-4">🏅 {{$t("Place")}} {{competitionMessage.place}} {{$t("out of")}}  {{competitionMessage.participants}} </h2>

              <v-btn
                 class="ma-2"
                 x-large
                 color="primary"
                 @click="goToCompetitionOverview(competitionMessage.competitionId)"
               >
                  {{$t("View the results")}}
               </v-btn>
            </v-card-text>
        </v-card>
      </v-dialog>

    </template>
    <template v-else-if="$route.name == 'ResetPassword'">
      <ResetPassword />
    </template>
    <template v-else-if="$route.name == 'ActivateAccount'">
      <ActivateAccount />
    </template>
    <template v-else>
      <Login />
    </template>

  </v-app>
</template>

<script>
import auth from '@/auth'
import Login from '@/components/Login.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import ActivateAccount from '@/components/ActivateAccount.vue'
import { mapGetters } from 'vuex'
import Snackbar from '@/components/Snackbar'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    Login,
    Snackbar,
    Cropper,
    ResetPassword,
    ActivateAccount
  },
  data () {
    return {
      isResetPassword: "",
      loading: true,
      profileUploading:false,
      menuVisible: true,
      mini: true,
      avText:'',
      userFullname:'',
      profilePicture:'',
      updateProfileDialog:false,
      cropDialog:false,
      selectedFile:'',
      mime_type: '',
      showCompetitionMessageDialog: false,
      competitionMessage: null
    }
  },
  created () {
    this.checkSession()
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }
  },
  watch:{
    authenticated:{
        handler () {
              this.checkSession()
              this.getCompetitionsMessage();
            },
            deep: true,
        }
  },
  methods: {
    async accept() {
      this.showUpdateUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    checkSession(){
      var scope = this
      if(auth.getUser().token==undefined){
        scope.loading = false
        return false;
      }
      this.axios.defaults.headers.common['Authorization'] = auth.getUser().token
      this.axios.get(this.$urlPrefix + "/Authentication/SessionIsValid", this.loginForm)
      .then(function(){
        scope.$store.dispatch('setAuthenticated', true)
        scope.$store.dispatch('setUser', auth.getUser())
        var usernames = auth.getUser().fullName.split(" ")
        scope.avText = usernames[0].charAt(0)+usernames[1].charAt(0);
        scope.userFullname = auth.getUser().fullName;
        scope.profilePicture = scope.$urlPrefix + "/img/profile-pictures/" + auth.getUser().id + ".png";
        scope.loading = false
      })
      .catch(function(){
        scope.$store.dispatch('setAuthenticated', false)
        scope.$store.dispatch('setUser', null)
        scope.loading = false
      })
    },
    getCompetitionsMessage(){
      var scope = this;
      let uri = this.$urlPrefix + '/Competition/GetCompetitionsMessage';
      this.axios.get(uri).then((response) => {
        if(response.data)
        {
          scope.competitionMessage = response.data;
          scope.showCompetitionMessageDialog = true;
        }
      });
    },
    logout() {
      auth.deleteUser()
      this.$store.dispatch('setAuthenticated', false)
    },
    openUpdateProfileDialog(){
      this.updateProfileDialog = true;
    },
    updateProfilePicture(){
      var scope = this
      const { canvas } = this.$refs.cropper.getResult();

      canvas.toBlob((blob) => {
        this.profilePicture = canvas.toDataURL();
        this.profileUploading = true
        let uri = this.$urlPrefix + '/User/UpdateProfilePicture';

        var formData = new FormData()
        formData.append('blob', blob)

        this.axios.post(uri, formData).then((response) => {
          scope.profileUploading = false
          if(response.status == 200){
            scope.profilePicture = canvas.toDataURL();
            scope.updateProfileDialog = false
            this.cropDialog = false;
          }else{
            scope.$store.dispatch('setSnack', {icon:"mdi-alert", color:'red', msg:"Profile Picture update failed"})
          }
        }).catch(function(){
          scope.profileUploading = false
          scope.$store.dispatch('setSnack', {icon:"mdi-alert", color:'red', msg:"Profile Picture update failed"})
        })

			}, this.mime_type);

    },
    onFileSelect(e) {
      const file = e.target.files[0]
      this.mime_type = file.type
      if (typeof FileReader === 'function') {
        this.cropDialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    onClickSelectFile(){
      this.$refs.FileInput.value = ''
    },
    goToCompetitionOverview(competitionId){
      console.log(competitionId)
      this.showCompetitionMessageDialog = false
      this.$router.push({name: 'CompetitionOverview', query: { id: competitionId}})
    }
  },
  computed: {...mapGetters({
      authenticated: 'authenticated',
      user: 'user'
    }),
  }
}
</script>

<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
  .v-navigation-drawer__border{
    width: 0px !important;
  }
  .v-list-item--link:before {
      background-color: transparent !important;
      border-left: 3px solid #0050ff;
  }

  .theme--light.v-list-item--active:before {
      opacity: 1 !important;
  }

  .v-list .v-list-item--active .v-icon {
      color: #0050ff !important;
  }

  .v-navigation-drawer__content::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }

  .v-navigation-drawer__content::-webkit-scrollbar
  {
    width: 3px;
    background-color: #F5F5F5;
  }

  .v-navigation-drawer__content::-webkit-scrollbar-thumb
  {
    background-color: #0050ff;
  }
  .v-list-item__title.title {
      text-align: center;
  }
  .profile-edit-btn {
      position: absolute !important;
      padding: 24px !important;
      background-color: #ffffff52;
  }
  .user-profile {
    width: 100%;
    text-align: center;
    display: block;
  }

  .profile-img {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .word-wrap{
    word-wrap: break-word;
  }

  .img-fluid{
    max-width: 100%;
    height: auto;
  }

  @media only screen and (max-width: 600px) {
    main{
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 0px !important;
    }
    header{
      left: 0px !important;
    }
    .change-image-btns{
      margin-left: -20px !important;
    }
  }
</style>
